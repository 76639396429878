define("discourse/plugins/discourse-topic-custom-fields/discourse/initializers/topic-custom-field-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-topic-custom-fields/discourse/lib/topic-custom-field"], function (_exports, _pluginApi, _decorators, _computed, _topicCustomField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "topic-custom-field-intializer",
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      const fieldName = siteSettings.topic_custom_field_name;
      const fieldType = siteSettings.topic_custom_field_type;
      (0, _pluginApi.withPluginApi)('0.11.2', api => {
        var _dec, _obj, _init;
        /*
         * type:        step
         * number:      5
         * title:       Show an input in the composer
         * description: If your field can be created or edited by users, you need
         *              to show an input in the composer.
         * references:  app/assets/javascripts/discourse/app/templates/composer.hbs,
         *              app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
         */

        /*
         * type:        step
         * number:      5.1
         * title:       Setup the composer connector class
         * description: Set the actions and properties you'll need in the
         *              composer connector template.
         * references:  app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
         */
        api.registerConnectorClass('composer-fields', 'composer-topic-custom-field-container', {
          setupComponent(attrs, component) {
            const model = attrs.model;
            let can_display = true;
            if (model.post || model.action == 'reply') {
              can_display = false;
            }
            let versions = [];
            (0, _topicCustomField.getVersions)().then(versions => {
              versions = versions;
              console.log(versions); // this will log the actual versions array
              if (!(0, _topicCustomField.isDefined)(model[fieldName]) && model.topic && model.topic[fieldName]) {
                model.set(fieldName, model.topic[fieldName]);
              }
              let props = {
                fieldName: fieldName,
                fieldValue: model.get(fieldName)
              };
              component.setProperties(Object.assign(props, (0, _topicCustomField.fieldInputTypes)(fieldType)));
              component.set("can_display", can_display);
              component.set("versions", versions);
            });

            // If the first post is being edited we need to pass our value from
            // the topic model to the composer model.
          },
          actions: {
            onChangeField(fieldValue) {
              this.set(`model.${fieldName}`, fieldValue);
            }
          }
        });

        /*
         * type:        step
         * number:      5.2
         * title:       Render an input in the composer
         * description: Render an input where the user can edit your field in the
         *              composer.
         * location:    plugins/discourse-topic-custom-fields/assets/javascripts/discourse/connectors/composer-fields/composer-topic-custom-field-container.hbs
         * references:  app/assets/javascripts/discourse/app/templates/composer.hbs
         */

        /*
         * type:        step
         * number:      6
         * title:       Show an input in topic title edit
         * description: If your field can be edited by the topic creator or
         *              staff, you may want to let them do this in the topic
         *              title edit view.
         * references:  app/assets/javascripts/discourse/app/templates/topic.hbs,
         *              app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
         */

        /*
         * type:        step
         * number:      6.1
         * title:       Setup the edit topic connector class
         * description: Set the actions and properties you'll need in the edit
         *              topic connector template.
         * references:  app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
         */
        api.registerConnectorClass('edit-topic', 'edit-topic-custom-field-container', {
          setupComponent(attrs, component) {
            const model = attrs.model;
            let versions = [];
            (0, _topicCustomField.getVersions)().then(versions => {
              versions = versions;
              console.log(versions);
              let props = {
                fieldName: fieldName,
                fieldValue: model.get(fieldName)
              };
              component.setProperties(Object.assign(props, (0, _topicCustomField.fieldInputTypes)(fieldType)));
              component.set("versions", versions);
            });
          },
          actions: {
            onChangeField(fieldValue) {
              this.set(`buffered.${fieldName}`, fieldValue);
            }
          }
        });

        /*
         * type:        step
         * number:      6.2
         * title:       Render an input in topic edit
         * description: Render an input where the user can edit your field in
         *              topic edit.
         * location:    plugins/discourse-topic-custom-fields/assets/javascripts/discourse/connectors/edit-topic/edit-topic-custom-field-container.hbs
         * references:  app/assets/javascripts/discourse/app/templates/topic.hbs
         */

        /*
         * type:        step
         * number:      7
         * title:       Serialize your field to the server
         * description: Send your field along with the post and topic data saved
         *              by the user when creating a new topic, saving a draft, or
         *              editing the first post of an existing topic.
         * references:  app/assets/javascripts/discourse/app/lib/plugin-api.js.es6,
         *              app/assets/javascripts/discourse/app/models/composer.js.es6
         */
        api.serializeOnCreate(fieldName);
        api.serializeToDraft(fieldName);
        api.serializeToTopic(fieldName, `topic.${fieldName}`);

        /*
         * type:        step
         * number:      8
         * title:       Display your field value
         * description: Display the value of your custom topic field below the 
         *              title in the topic, and after the title in the topic
         *              list.
         */

        /*
         * type:        step
         * number:      8.1
         * title:       Setup the topic title connector component
         * description: Set the actions and properties you'll need in the topic
         *              title
         *              connector template.
         * references:  app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
         */
        api.registerConnectorClass('topic-title', 'topic-title-custom-field-container', {
          setupComponent(attrs, component) {
            const model = attrs.model;
            const controller = container.lookup('controller:topic');
            component.setProperties({
              fieldName: fieldName,
              fieldValue: model.get(fieldName),
              showField: !controller.get('editingTopic') && (0, _topicCustomField.isDefined)(model.get(fieldName))
            });
            controller.addObserver('editingTopic', () => {
              if (this._state === 'destroying') return;
              component.set('showField', !controller.get('editingTopic') && (0, _topicCustomField.isDefined)(model.get(fieldName)));
            });
            model.addObserver(fieldName, () => {
              if (this._state === 'destroying') return;
              component.set('fieldValue', model.get(fieldName));
            });
          }
        });

        /*
         * type:        step
         * number:      8.2
         * title:       Render the value in the topic title plugin outlet
         * description: Render the value of the custom topic field under the
         *              topic title, unless the topic title is currently being
         *              edited.
         * location:    plugins/discourse-topic-custom-fields/assets/javascripts/discourse/connectors/topic-title/topic-title-custom-field-container.hbs
         * references:  app/assets/javascripts/discourse/app/templates/topic.hbs
         */

        /*
         * type:        step
         * number:      8.3
         * title:       Setup the topic list item component
         * description: Setup the properties you'll need in the topic list item
         *              template. You can't do this in a connector js file, as
         *              the topic list item is a raw template, which doesn't
         *              support js.
         * references:  app/assets/javascripts/discourse/app/components/topic-list-item.js.es6,
         *              app/assets/javascripts/discourse/app/helpers/raw-plugin-outlet.js.es6
         */
        api.modifyClass('component:topic-list-item', (_dec = (0, _decorators.default)('customFieldValue'), (_obj = {
          customFieldName: fieldName,
          customFieldValue: (0, _computed.alias)(`topic.${fieldName}`),
          showCustomField: value => (0, _topicCustomField.isDefined)(value)
        }, (_applyDecoratedDescriptor(_obj, "showCustomField", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "showCustomField"), _init = _init ? _init.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init;
          }
        }), _obj)), _obj)));

        /*
         * type:        step
         * number:      8.4
         * title:       Render the value in the topic list after title plugin
         *              outlet
         * description: Render the value of the custom topic field in the topic
         *              list, after the topic title.
         * location:    plugins/discourse-topic-custom-fields/assets/javascripts/discourse/connectors/topic-list-after-title/topic-list-after-title-custom-field-container.hbr
         * references:  app/assets/javascripts/discourse/app/templates/list/topic-list-item.hbr
         */
      });
    }
  };
});